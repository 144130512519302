@font-face {
  font-family: Inter;
  font-display: swap;
  src: url(../public/assets/fonts/Inter-Bold.woff2) format('woff2');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: Inter;
  font-display: swap;
  src: url(../public/assets/fonts/Inter-Regular.woff2) format('woff2');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: Inter;
  font-display: swap;
  src: url(../public/assets/fonts/Inter-SemiBold.woff2) format('woff2');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: Inter;
  font-display: swap;
  src: url(../public/assets/fonts/Inter-Medium.woff2) format('woff2');
  font-weight: 500;
  font-style: normal;
}
:root {
  --primary: #121c2d;
  --secondary: #0263e0;
  --dark: #000;
  --bg-primary: #fff;
  --bg-secondary: #8cb8e5;
  --bg-accent: #edfdf3;
  --bg-muted: #f4f4f6;
  --bg-light: #ebf4ff;
  --text-primary: #121c2d;
  --text-secondary: #0263e0;
  --text-accent: #030b5d;
  --text-inverse: #fff;
  --text-muted: #606b85;
  --text-dark: #000;
  --text-error: #d61f1f;
  --border-primary: #8891aa;
  --border-secondary: #e1e3ea;
  --border-accent: #030b5d;
  --border-dark: #06033a;
  --border-error: #d61f1f;
  --h1-size: 2rem;
  --h2-size: 1.5rem;
}
* {
  padding: 0;
  margin: 0;
  border: 0;
}
*,
*::after,
*::before {
  box-sizing: border-box;
}
*::after,
*::before {
  display: inline-block;
}
::-webkit-scrollbar {
  width: 1px;
  height: 1px;
  margin-left: 2px;
}
::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}
::-webkit-scrollbar-thumb {
  background-color: var(--dark);
}
body,
html {
  height: 100%;
  min-width: 320px;
}
body {
  color: var(--text-primary);
  line-height: 1.25rem;
  font-family: Inter;
  font-size: 0.875rem;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
button,
input,
textarea {
  font-family: Inter;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  background-color: rgba(0, 0, 0, 0);
}
input,
textarea {
  width: 100%;
}
label {
  display: inline-block;
}
button,
option,
select {
  cursor: pointer;
}
a {
  display: inline-block;
  color: inherit;
  text-decoration: none;
}
ul li {
  list-style: none;
}
img {
  vertical-align: top;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1;
}
.lock body {
  overflow: hidden;
  touch-action: none;
  -ms-scroll-chaining: none;
  overscroll-behavior: none;
}
.wrapper {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
@supports (overflow: clip) {
  .wrapper {
    overflow: clip;
  }
}
.wrapper > main {
  flex: 1 1 auto;
}
.wrapper > * {
  min-width: 0;
}
[class*='__container'] {
  max-width: 95.625rem;
  margin: 0 auto;
  padding: 0 0.9375rem;
}
[class*='--gc'] {
  display: grid;
  min-width: 0;
  grid-template-columns: 0.9375rem 1fr minmax(auto, 93.75rem) 1fr 0.9375rem;
}
[class*='--gc'] > * {
  min-width: 0;
  grid-column: 3/4;
}
.label {
  max-width: 41.0625rem;
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;
  font-weight: 700;
  line-height: 1.25rem;
}
.label--fw {
  width: 100%;
}
.btn {
  height: 2rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  -webkit-column-gap: 0.5rem;
  -moz-column-gap: 0.5rem;
  column-gap: 0.5rem;
  font-weight: 400;
  border-radius: 0.25rem;
  transition: 0.3s;
}
.btn-primary {
  padding-inline: 0.875rem;
  line-height: 2rem;
  color: var(--text-inverse);
  background: var(--bg-secondary);
}
.btn-accent {
  background: var(--secondary);
}
.btn-round {
  border-radius: 50%;
}
.btn-muted {
  background: var(--bg-muted);
}
.btn-outline {
  padding-inline: 0.75rem;
  font-weight: 600;
  border: 1px solid var(--border-primary);
}
.btn-remove {
  padding-inline: 0.75rem;
  font-weight: 600;
  color: var(--text-error);
  border: 1px solid var(--border-error);
}
input[type='email'],
input[type='tel'],
input[type='text'],
textarea {
  -webkit-appearance: none;
  appearance: none;
}
.input {
  min-height: 2.25rem;
  width: 100%;
  padding-inline: 1rem;
  border: 1px solid var(--border-primary);
  border-radius: 0.25rem;
}
.input::-webkit-input-placeholder {
  font-weight: 500;
  color: var(--text-primary);
}
.input:-ms-input-placeholder {
  font-weight: 500;
  color: var(--text-primary);
}
.input::-ms-input-placeholder {
  font-weight: 500;
  color: var(--text-primary);
}
.input::placeholder {
  font-weight: 500;
  color: var(--text-primary);
}
.textarea {
  min-height: 4.75rem;
  width: 100%;
  padding: 0.5rem 0.75rem;
  font-weight: 500;
  background: var(--bg-muted);
  border: 1px solid var(--border-primary);
  border-radius: 0.25rem;
  resize: none;
}
.textarea::-webkit-input-placeholder {
  font-weight: 500;
  color: inherit;
}
.textarea:-ms-input-placeholder {
  font-weight: 500;
  color: inherit;
}
.textarea::-ms-input-placeholder {
  font-weight: 500;
  color: inherit;
}
.textarea::placeholder {
  font-weight: 500;
  color: inherit;
}
.options {
  min-height: 2.25rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  -webkit-column-gap: 1rem;
  -moz-column-gap: 1rem;
  column-gap: 1rem;
  row-gap: 0.375rem;
}
.options__item {
  position: relative;
  cursor: pointer;
}
.options__item:not(:last-child) {
  margin-bottom: em(5);
}
.options__input {
  width: 0;
  height: 0;
  opacity: 0;
  position: absolute;
}
.options__input:focus-visible + .options__label:before {
  box-shadow: 0 0 5px #000;
}
.options__input:checked + .options__label:before {
  background: var(--secondary);
}
.options__input:checked + .options__label:after {
  -webkit-transform: scale(1);
  transform: scale(1);
}
.options__label {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  gap: 10px;
}
.options__label:before {
  content: '';
  align-self: flex-start;
  width: 1rem;
  height: 1rem;
  flex: 0 0 1rem;
  border-radius: 50%;
  border: 1px solid var(--border-primary);
}
.options__label:after {
  content: '';
  transition: all 0.3s ease 0s;
  -webkit-transform: scale(0);
  transform: scale(0);
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 50%;
  background-color: var(--bg-primary);
  position: absolute;
  left: 5px;
  top: 5px;
}
.options__text {
  font-weight: 500;
}
.select {
  min-width: 0;
  position: relative;
}
.select__body {
  position: relative;
}
.select__title {
  color: inherit;
  text-align: left;
  border: 1px solid var(--border-primary);
  background-color: var(--bg-primary);
  cursor: pointer;
  width: 100%;
  border-radius: 0.25rem;
  overflow: hidden;
}
.select__value {
  display: flex;
  min-width: 0;
  align-items: center;
  height: 2.25rem;
  gap: 0.625rem;
}
.select__value > * {
  flex: 1 1 auto;
}
.select__value:after {
  content: '';
  width: 2.25rem;
  height: 100%;
  transition: all 0.3s ease 0s;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: '/assets/img/select-arrow.webp';
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-color: var(--bg-muted);
}
.select__value._select-pseudo-label::before {
  content: attr(data-pseudo-label);
  opacity: 0.5;
}
.select__content {
  padding-left: 0.75rem;
  flex: 1 1 auto;
  overflow: hidden;
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.select__text {
  flex: 1 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.select__input {
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
  height: 100%;
}
.select__options {
  position: absolute;
  top: calc(100% + 0.0625rem);
  border-radius: 0 0 4px 4px;
  min-width: 100%;
  left: 0;
  background-color: var(--bg-primary);
  border: 1px solid var(--border-secondary);
  border-radius: 0.25rem;
  box-shadow: 0 0.25rem 1rem rgba(18, 28, 45, 0.2);
}
.select__scroll {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 11.25rem;
}
.select__option {
  min-height: 2.25rem;
  width: 100%;
  padding-inline: 1rem;
  font-weight: 500;
  text-align: left;
  cursor: pointer;
  color: inherit;
}
.select__option:not(:last-child) {
  border-bottom: 1px solid var(--border-secondary);
}
.select__option._select-selected {
  background-color: #eee;
}
.select__row {
  display: inline-flex;
  align-items: center;
  -webkit-column-gap: 0.4375rem;
  -moz-column-gap: 0.4375rem;
  column-gap: 0.4375rem;
}
.select--show-top .select__options {
  top: auto;
  bottom: calc(100% - 0.0625rem);
  border: 1px solid #d9d9d9;
  border-bottom: 0;
  border-radius: 4px 4px 0 0;
  padding: 5px 0 10px 0;
}
._select-tag {
  cursor: pointer;
}
.qs-datepicker-container {
  margin-top: 3px;
  position: absolute;
  z-index: 9001;
  width: 12.625rem;
  padding: 0.875rem 0.625rem;
  display: flex;
  flex-direction: column;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
  background: var(--bg-primary);
  -webkit-box-shadow: 0 1.25em 1.25em -0.9375em rgba(0, 0, 0, 0.3);
  border: 1px solid #cacdd8;
  border-radius: 0.25rem;
}
.qs-datepicker-container * {
  box-sizing: border-box;
}
.qs-datepicker-container.qs-centered {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.qs-datepicker-container.qs-hidden {
  display: none;
}
.qs-datepicker .qs-overlay {
  position: absolute;
  top: 0;
  left: 0;
  background: var(--bg-light);
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 1;
  transition: opacity 0.3s;
  display: flex;
  flex-direction: column;
}
.qs-datepicker .qs-overlay.qs-hidden {
  opacity: 0;
  z-index: -1;
}
.qs-datepicker .qs-overlay .qs-overlay-year {
  background: rgba(0, 0, 0, 0);
  border: none;
  border-bottom: 1px solid var(--border-secondary);
  border-radius: 0;
  padding: 0.25em 0;
  width: 80%;
  text-align: center;
  margin: 0.625rem auto 0;
  display: block;
}
.qs-datepicker .qs-overlay .qs-overlay-year::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.qs-datepicker .qs-overlay .qs-close {
  padding: 0.5em;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
}
.qs-datepicker .qs-overlay .qs-submit {
  margin: 10px auto;
  height: 2rem;
  max-width: -webkit-max-content;
  max-width: max-content;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  -webkit-column-gap: 0.5rem;
  -moz-column-gap: 0.5rem;
  column-gap: 0.5rem;
  font-weight: 400;
  border-radius: 0.25rem;
  padding-inline: 0.875rem;
  line-height: 2rem;
  color: var(--text-inverse);
  background: var(--bg-secondary);
  cursor: pointer;
}
.qs-datepicker .qs-overlay .qs-submit.qs-disabled {
  border-color: gray;
  cursor: not-allowed;
}
.qs-datepicker .qs-overlay .qs-overlay-month-container {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  font-weight: 500;
}
.qs-datepicker .qs-overlay .qs-overlay-month {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 33.33333%;
  cursor: pointer;
  transition: opacity 0.15s;
}
.qs-datepicker .qs-overlay .qs-overlay-month.active,
.qs-datepicker .qs-overlay .qs-overlay-month:hover {
  opacity: 1;
}
.qs-datepicker .qs-controls {
  margin-bottom: 0.875rem;
  width: 100%;
  display: flex;
  -webkit-column-gap: 0.375rem;
  -moz-column-gap: 0.375rem;
  column-gap: 0.375rem;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  align-items: center;
  flex-grow: 1;
  flex-shrink: 0;
  -webkit-filter: blur(0);
  filter: blur(0);
  transition: -webkit-filter 0.3s;
  transition: filter 0.3s;
  transition:
    filter 0.3s,
    -webkit-filter 0.3s;
  transition:
    filter 0.3s,
    -webkit-filter 0.3s;
}
.qs-datepicker .qs-controls.qs-blur {
  -webkit-filter: blur(5px);
  filter: blur(5px);
}
.qs-datepicker .qs-arrow {
  height: 1.5625em;
  width: 1.5625em;
  position: relative;
  cursor: pointer;
  border-radius: 0.26392em;
  transition: background 0.15s;
}
.qs-datepicker .qs-arrow:after {
  content: '';
  border: 0.39062em solid transparent;
  position: absolute;
  top: 50%;
  transition: border 0.2s;
}
.qs-datepicker .qs-arrow.qs-left:after {
  position: absolute;
  inset: 0;
  background-image: url(../public/assets/img/date_prev.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50% 50%;
}
.qs-datepicker .qs-arrow.qs-right:after {
  position: absolute;
  inset: 0;
  background-image: url(../public/assets/img/date_next.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50% 50%;
}
.qs-datepicker .qs-month-year {
  margin-right: auto;
  font-weight: 700;
  transition: border 0.2s;
  cursor: pointer;
}
.qs-datepicker .qs-month-year:hover {
  border-bottom: 1px solid gray;
}
.qs-datepicker .qs-month-year:active:focus,
.qs-datepicker .qs-month-year:focus {
  outline: 0;
}
.qs-datepicker .qs-month {
  padding-right: 0.5ex;
}
.qs-datepicker .qs-year {
  padding-left: 0.5ex;
}
.qs-datepicker .qs-squares {
  display: flex;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  font-weight: 500;
  flex-wrap: wrap;
  -webkit-filter: blur(0);
  filter: blur(0);
  transition: -webkit-filter 0.3s;
  transition: filter 0.3s;
  transition:
    filter 0.3s,
    -webkit-filter 0.3s;
  transition:
    filter 0.3s,
    -webkit-filter 0.3s;
}
.qs-datepicker .qs-squares.qs-blur {
  -webkit-filter: blur(5px);
  filter: blur(5px);
}
.qs-datepicker .qs-square {
  width: 1.5625rem;
  height: 1.5625rem;
  display: flex;
  color: #666;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 50%;
}
.qs-datepicker .qs-square.qs-current {
  font-weight: 500;
}
.qs-datepicker .qs-square.qs-current span {
  text-decoration: underline;
}
.qs-datepicker .qs-square.qs-active:not(.qs-empty) {
  background: #fb3f4a;
  color: var(--bg-primary);
}
.qs-datepicker .qs-square.qs-range-date-start:not(.qs-range-date-6) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.qs-datepicker .qs-square.qs-range-date-middle {
  background: #d4ebf2;
}
.qs-datepicker
  .qs-square.qs-range-date-middle:not(.qs-range-date-0):not(.qs-range-date-6) {
  border-radius: 0;
}
.qs-datepicker .qs-square.qs-range-date-middle.qs-range-date-0 {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.qs-datepicker .qs-square.qs-range-date-end:not(.qs-range-date-0),
.qs-datepicker .qs-square.qs-range-date-middle.qs-range-date-6 {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.qs-datepicker .qs-square.qs-disabled span {
  opacity: 0.2;
}
.qs-datepicker .qs-square.qs-empty {
  cursor: default;
}
.qs-datepicker .qs-square.qs-disabled {
  cursor: not-allowed;
}
.qs-datepicker .qs-square.qs-day {
  cursor: default;
  font-weight: 500;
  color: #333;
}
.qs-datepicker
  .qs-square:not(.qs-empty):not(.qs-disabled):not(.qs-day):not(
    .qs-active
  ):hover {
  background: #fb3f4a;
  color: var(--text-inverse);
}
.qs-datepicker .qs-event {
  position: relative;
}
.qs-datepicker .qs-event:after {
  content: '';
  position: absolute;
  width: 0.46875em;
  height: 0.46875em;
  border-radius: 50%;
  background: #07f;
  bottom: 0;
  right: 0;
}
.qs-datepicker .qs-event.qs-disabled:after,
.qs-datepicker .qs-event.qs-empty:after {
  background: #cce4ff;
}
.qs-left {
  order: 1;
}
.qs-right {
  order: 1;
}
.menu__list {
  height: 100%;
  width: 0;
  border-right: 1px solid var(--border-secondary);
  transition: width 0.3s;
}
.menu-open .menu__list {
  width: 3.25rem;
}
.menu__link {
  height: 3.25rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.icon-menu {
  width: 1.25rem;
  height: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tabs {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.tabs__navigation {
  width: 100%;
  display: flex;
  align-items: center;
  -webkit-column-gap: 1.5rem;
  -moz-column-gap: 1.5rem;
  column-gap: 1.5rem;
  border-bottom: 1px solid var(--border-secondary);
}
.tabs__title {
  padding: 0.75rem 0.25rem;
  font-weight: 600;
  line-height: 1.25rem;
}
.tabs__title._tab-active {
  color: var(--text-secondary);
  border-bottom: 2px solid var(--border-accent);
}
.tabs__content {
  margin-top: 1.5rem;
}
.tabs__body,
.tabs__content {
  height: 100%;
}
.ibg {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.ibg--top {
  -o-object-position: top;
  object-position: top;
}
.ibg--bottom {
  -o-object-position: bottom;
  object-position: bottom;
}
.ibg--left {
  -o-object-position: left;
  object-position: left;
}
.ibg--right {
  -o-object-position: right;
  object-position: right;
}
.ibg--contain {
  -o-object-fit: contain;
  object-fit: contain;
}
.page {
  position: relative;
  display: flex;
}
.page__container {
  flex-grow: 1;
}
.page__wrap {
  width: 100%;
  display: flex;
}
.page__left {
  max-width: 25.3125rem;
  width: 100%;
  border-right: 1px solid var(--border-secondary);
}
.page__top {
  padding: 1.125rem;
}
.page__right {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
}
.page__topbar {
  margin-inline: 1.6875rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
}
.page__back {
  margin-top: 1.25rem;
  display: flex;
  align-items: center;
  -webkit-column-gap: 0.25rem;
  -moz-column-gap: 0.25rem;
  column-gap: 0.25rem;
  color: var(--text-secondary);
}
.page__delete {
  margin-top: 1.25rem;
  color: var(--text-error);
}
.page__bottom {
  margin-top: auto;
  width: 100%;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid var(--border-secondary);
}
.page__bottom .btn {
  min-height: 2.25rem;
}
.page__bottom .btn-outline {
  margin-left: auto;
  max-width: -webkit-max-content;
  max-width: max-content;
}
.page-header {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}
.page-header:not(:last-child) {
  margin-bottom: 1.125rem;
}
.page-header__title {
  font-size: var(--h1-size);
  font-weight: 500;
  color: var(--text-dark);
}
.page-header__title:not(:last-child) {
  margin-bottom: 0.4375rem;
}
.page-header__subtitle {
  font-weight: 500;
  color: var(--text-dark);
}
.page-header__btn {
  width: 2.25rem;
  height: 2.25rem;
}
.search {
  display: flex;
  align-items: center;
  border: 1px solid var(--border-primary);
  border-radius: 0.25rem;
  overflow: hidden;
}
.search:not(:last-child) {
  margin-bottom: 1.5rem;
}
.search__btn {
  height: 2.25rem;
  width: 2.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border-right: 1px solid var(--border-secondary);
  border-radius: 0;
}
.search__input {
  min-height: 2.25rem;
  padding-inline: 1rem;
}
.filter__row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-column-gap: 1rem;
  -moz-column-gap: 1rem;
  column-gap: 1rem;
}
.filter__btn {
  padding: 0;
  width: 1.75rem;
  height: 1.75rem;
  flex-shrink: 0;
  border-radius: 50%;
}
.filter__body {
  overflow-y: auto;
  position: absolute;
  top: 0;
  left: -100%;
  z-index: 2;
  max-width: 23.25rem;
  width: 100%;
  height: 100%;
  padding-block: 0.625rem;
  background: var(--bg-primary);
  transition: 0.3s;
}
.filter__body:not(.filter__body-history) {
  box-shadow: 0 0.25rem 1rem rgba(18, 28, 45, 0.2);
}
.filter__body:not(.filter__body-history).active {
  left: 28.4375rem;
}
.filter__body-history {
  left: unset;
  top: 0;
  right: -100%;
  padding-bottom: 3rem;
  border: 1px solid var(--border-secondary);
  border-radius: 0.25rem;
}
.filter__body-history.active {
  right: -1.875rem;
}
.filter__head {
  padding-inline: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.filter__head:not(:last-child) {
  margin-bottom: 1.5rem;
}
.filter__title {
  font-size: var(--h2-size);
  line-height: 2rem;
}
.filter__close {
  height: 2.25rem;
  width: 2.25rem;
  flex-shrink: 0;
}
.filter__search {
  margin-inline: 1rem;
}
.filter__widget {
  padding-inline: 1rem;
}
.filter__widget:not(:last-child) {
  margin-bottom: 0.8125rem;
  padding-bottom: 0.8125rem;
  border-bottom: 1px solid var(--border-secondary);
}
.filter__line {
  display: grid;
  grid-template-columns: 1fr 1fr;
  -webkit-column-gap: 0.25rem;
  -moz-column-gap: 0.25rem;
  column-gap: 0.25rem;
}
.filter__select-label {
  font-weight: 500;
}
.filter__select-small .select__option {
  min-height: 1.625rem;
}
.filter .options {
  display: flex;
}
.filter__date,
.filter__select {
  display: flex;
  flex-direction: column;
  row-gap: 0.4375rem;
}
.categories__item {
  height: 1.75rem;
  padding-inline: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  text-wrap: nowrap;
  border: 1px solid var(--border-primary);
  border-radius: 6.25rem;
}
.categories__item.active {
  color: var(--text-accent);
  background: #cce4ff;
  border-color: var(--border-accent);
}
.persons {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: calc(100vh - 20.3125rem);
}
.person-card {
  min-height: 4.5rem;
  padding: 0.9375rem 1rem;
  display: flex;
  align-items: center;
  -webkit-column-gap: 0.75rem;
  -moz-column-gap: 0.75rem;
  column-gap: 0.75rem;
}
.person-card:not(:has(.active)):not(:last-child) {
  border-bottom: 1px solid var(--border-secondary);
}
.person-card.active,
.person-card:hover {
  position: relative;
  background: var(--bg-light);
}
.person-card.active::before,
.person-card:hover::before {
  content: '';
  position: absolute;
  top: 0;
  left: 1px;
  height: 100%;
  width: 1px;
  background: var(--border-dark);
}
.person-card.active::after,
.person-card:hover::after {
  content: '';
  position: absolute;
  top: 0;
  right: -1px;
  height: 100%;
  width: 1px;
  background: var(--border-dark);
}
.person-card__avatar {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
}
.person-card__content {
  text-align: left;
  line-height: 1.25rem;
}
.person-card__date {
  color: var(--text-muted);
}
.grid {
  display: grid;
  grid-template-columns: repeat(3, 12.625rem);
  gap: 1.5rem;
}
.grid__info .select {
  max-width: 12.625rem;
}
.grid__info--fw {
  grid-column: span 3;
}
.grid__title {
  font-weight: 700;
  line-height: 1.25rem;
}
.grid__value {
  line-height: 1.5rem;
}
.content {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 1.5rem;
}
.content-row {
  flex-direction: row;
}
.content-history {
  max-width: 60.5rem;
  position: relative;
}
.content__title {
  font-size: var(--h2-size);
}
.content__block {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
}
.content__wrap {
  margin-inline: 1.6875rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 1.5rem;
}
.content__wrap--fw {
  max-width: 41.0625rem;
}
.content__wrap--history {
  max-width: 36rem;
  width: 100%;
  align-items: unset;
}
.content__wrap--history .select {
  max-width: -webkit-max-content;
  max-width: max-content;
}
.content__wrap--calendar {
  max-width: 60.375rem;
  width: 100%;
}
.content__form {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 1.5rem;
}
.content__row {
  display: flex;
  align-items: center;
  -webkit-column-gap: 2.125rem;
  -moz-column-gap: 2.125rem;
  column-gap: 2.125rem;
}
.content__row .btn {
  max-width: -webkit-max-content;
  max-width: max-content;
}
.content__text {
  max-width: 25.9375rem;
  font-weight: 500;
  line-height: 1.25rem;
}
.note {
  max-width: 35.4375rem;
  font-weight: 500;
}
.table {
  border-collapse: collapse;
}
.table__head {
  font-weight: 600;
}
.table__item {
  padding: 0.625rem 1rem;
  font-weight: 400;
  text-align: center;
  color: var(--text-dark);
  border: 2px solid var(--dark);
}
.table__total {
  font-weight: 600;
}
.markers__line {
  display: grid;
  grid-template-columns: repeat(8, 4.9375rem);
  gap: 0.875rem 0.25rem;
}
.markers__line:not(:last-child) {
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #cacdd8;
}
.header {
  background: var(--bg-secondary);
}
.header__inner {
  min-height: 3.8125rem;
  padding-inline: 1rem;
  display: flex;
  align-items: center;
  -webkit-column-gap: 1.5625rem;
  -moz-column-gap: 1.5625rem;
  column-gap: 1.5625rem;
}
.profile {
  position: relative;
  margin-left: auto;
  display: flex;
  align-items: center;
}
.profile__row {
  display: flex;
  align-items: center;
  -webkit-column-gap: 0.5625rem;
  -moz-column-gap: 0.5625rem;
  column-gap: 0.5625rem;
}
.profile__name {
  height: 1.75rem;
  padding: 0.25rem 1.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-inverse);
  border: 1px solid var(--border-secondary);
  border-radius: 0.375rem;
}
.profile__avatar {
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 50%;
}
.profile__btn {
  height: 1.25rem;
  width: 1.25rem;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition:
    transform 0.3s,
    -webkit-transform 0.3s;
}
.profile__btn.active {
  -webkit-transform: rotateZ(180deg);
  transform: rotateZ(180deg);
}
.profile__actions {
  position: absolute;
  right: 0;
  bottom: -4.1875rem;
  display: none;
  background: var(--bg-primary);
  border: 1px solid var(--border-primary);
  border-radius: 0.25rem;
  box-shadow: 0 0.25rem 1rem rgba(18, 28, 45, 0.2);
}
.profile__actions.active {
  display: block;
}
.profile__action {
  min-height: 2.625rem;
  min-width: -webkit-max-content;
  min-width: max-content;
  padding-inline: 1.1875rem;
  display: flex;
  align-items: center;
  -webkit-column-gap: 0.5rem;
  -moz-column-gap: 0.5rem;
  column-gap: 0.5rem;
  line-height: 1.25rem;
}
.footer {
  min-height: 4.1875rem;
  background: var(--bg-secondary);
}
.auth {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.auth__title {
  font-size: var(--h1-size);
  font-weight: 500;
}
.auth__form {
  min-width: 19.625rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1.5rem;
}
.main-menu {
  padding-block: 3.125rem;
  max-width: 56rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
}
.main-menu__link {
  min-height: 5.9375rem;
  padding-inline: 1.6875rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-column-gap: 1rem;
  -moz-column-gap: 1rem;
  column-gap: 1rem;
  font-size: var(--h2-size);
  font-weight: 500;
  border: 1px solid var(--border-primary);
  border-radius: 0.25rem;
}
.files {
  display: grid;
  grid-template-columns: repeat(3, 13.9375rem);
  gap: 1.375rem;
}
.file {
  position: relative;
  min-height: 8.75rem;
  display: flex;
  flex-direction: column;
}
.file__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}
.file__delete {
  position: absolute;
  top: 0;
  right: 0;
}
.file .btn {
  width: 100%;
}
.add-files__items {
  margin-bottom: 1.4375rem;
  padding: 1.375rem;
  display: grid;
  grid-template-columns: 14rem 14rem 14rem 10.3125rem;
  gap: 1.625rem;
  border: 1px solid #cacdd8;
  border-radius: 0.25rem;
}
.add-files__item {
  min-height: 10.1875rem;
  display: flex;
  flex-direction: column;
  font-weight: 500;
}
.add-files__preview {
  margin-bottom: 0.3125rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #cacdd8;
  border-radius: 0.3125rem;
}
.add-files__name {
  color: var(--dark);
}
.add-files__size {
  color: #666;
}
.add-files__field {
  max-height: 7.375rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-weight: 500;
  text-align: center;
  color: #666;
  border: 1px dashed #c4c4c4;
  border-radius: 0.1875rem;
}
.add-files__field span {
  font-size: 1.75rem;
}
.history {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
}
.history-item {
  min-height: 3.75rem;
  width: 100%;
  display: flex;
  -webkit-column-gap: 0.5rem;
  -moz-column-gap: 0.5rem;
  column-gap: 0.5rem;
}
.history-item:not(:last-child) {
  align-items: center;
}
.history-item:not(:last-child) .history-item__left {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 0.3125rem;
}
.history-item:not(:last-child) .history-item__left::after {
  content: '';
  display: block;
  width: 1px;
  height: 100%;
  border: 1px dashed #cacdd8;
}
.history-item._showmore-active .history-item__show {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.history-item__img {
  width: 1.75rem;
  height: 1.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  border: 1px solid var(--border-primary);
  border-radius: 50%;
}
.history-item__head {
  padding-top: 4px;
  display: flex;
  align-items: center;
  -webkit-column-gap: 0.375rem;
  -moz-column-gap: 0.375rem;
  column-gap: 0.375rem;
  line-height: 1rem;
}
.history-item__body {
  max-width: 25rem;
  display: flex;
  flex-direction: column;
  row-gap: 0.375rem;
}
.history-item__title {
  color: var(--text-muted);
}
.history-item__icon {
  width: 1.25rem;
  height: 1.25rem;
}
.history-item__bottom,
.history-item__content {
  display: flex;
  align-items: center;
  -webkit-column-gap: 0.375rem;
  -moz-column-gap: 0.375rem;
  column-gap: 0.375rem;
  color: var(--text-muted);
}
.history-item__actions {
  margin-left: auto;
  display: flex;
  align-items: center;
  -webkit-column-gap: 0.375rem;
  -moz-column-gap: 0.375rem;
  column-gap: 0.375rem;
}
.history-item__btn {
  width: 1.25rem;
  height: 1.25rem;
  transition: 0.3s;
}
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-column-gap: 0.5625rem;
  -moz-column-gap: 0.5625rem;
  column-gap: 0.5625rem;
}
.pagination__item.active {
  font-weight: 500;
}
.reporting {
  height: 100%;
  max-width: 86.25rem;
  margin: 0 auto;
  padding-block: 3.4375rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.reporting__subtitle,
.reporting__title {
  font-size: var(--h2-size);
  text-align: center;
  color: var(--dark);
}
.reporting__title:not(:last-child) {
  margin-bottom: 1.5rem;
}
.reporting__subtitle {
  font-weight: 400;
}
.reporting__subtitle:not(:last-child) {
  margin-bottom: 3.4375rem;
}
.reporting .table {
  width: 100%;
}
.reporting .table__item {
  text-align: left;
}
.events {
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
}
.event {
  display: flex;
  flex-direction: column;
  row-gap: 0.875rem;
}
.event__title {
  padding-inline: 0.4375rem;
  border-radius: 0.25rem;
}
.event__title span {
  font-weight: 600;
}
.calendar {
  margin-bottom: 3.4375rem;
}
.calendar__head {
  min-height: 4.25rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 600;
  text-align: center;
  border: 1px solid var(--border-secondary);
  border-bottom: none;
}
.calendar__body {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}
.calendar__body--day {
  grid-template-columns: 8.5rem 1fr 1fr 1fr;
}
.calendar__label {
  font-weight: 600;
}
.calendar__label--center {
  text-align: center;
}
.calendar__control {
  display: flex;
  align-items: center;
  -webkit-column-gap: 0.75rem;
  -moz-column-gap: 0.75rem;
  column-gap: 0.75rem;
}
.calendar__item {
  min-height: 4.25rem;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  border: 1px solid var(--border-secondary);
}
.calendar__item--accent {
  background: var(--bg-accent);
}
.calendar__item-content {
  padding: 0.8125rem;
}
.calendar-event {
  padding: 0.375rem;
  border-left: 4px solid var(--border-primary);
  border-radius: 0.25rem;
}
.calendar-event__time {
  font-weight: 500;
}
.calendar-event__name {
  font-weight: 600;
}
.letter {
  height: 100%;
  max-width: 56.25rem;
  margin: 0 auto;
  padding-block: 6.25rem;
  color: var(--dark);
}
.letter__footer,
.letter__header,
.letter__row {
  display: flex;
  justify-content: space-between;
}
.letter__header:not(:last-child) {
  margin-bottom: 3.4375rem;
}
.letter__input {
  border-bottom: 1px solid var(--dark);
}
.letter__descr,
.letter__title {
  text-align: center;
}
.letter__title:not(:last-child) {
  margin-bottom: 3.4375rem;
}
.letter__text:not(:last-child) {
  margin-bottom: 0.875rem;
}
.letter .table {
  width: 100%;
}
.letter .table:not(:last-child) {
  margin-bottom: 1.5rem;
}
.letter .table__item {
  text-align: left;
}
.letter__row:not(:last-child) {
  margin-bottom: 1.5rem;
}
.letter__descr:not(:last-child) {
  margin-bottom: 2.75rem;
}
.letter__right {
  text-align: right;
}
.letter__date {
  display: flex;
  align-items: flex-end;
}
.letter__day {
  width: 1.25rem;
}
.letter__mounth {
  width: 4rem;
}
.letter__line {
  width: 5.625rem;
}
.swiper {
  overflow: hidden;
}
.swiper-initialized {
  touch-action: pan-y;
}
.swiper-wrapper {
  width: 100%;
  height: 100%;
  box-sizing: content-box;
  display: flex;
  position: relative;
}
.swiper-vertical .swiper-wrapper {
  flex-direction: column;
}
.swiper-autoheight .swiper-wrapper {
  align-items: flex-start;
}
.swiper-initialized .swiper-slide {
  flex-shrink: 0;
}
.swiper-android .swiper-slide,
.swiper-android .swiper-wrapper {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.swiper-button-lock {
  display: none !important;
}
@media (any-hover: hover) {
  .btn-primary:hover {
    background: #5897d6;
  }
  .btn-accent:hover {
    background: #79a6e0;
  }
  .btn-muted:hover {
    background: #6393d3;
  }
  .btn-outline:hover {
    background: var(--bg-muted);
  }
  .btn-remove:hover {
    background: #ffebeb;
  }
  .main-menu__link:hover {
    background: var(--bg-accent);
  }
}
