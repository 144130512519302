.main-menu__link {
  width: 100%;
}
.react-datepicker {
  z-index: 1000;
  position: absolute;
}

.filter__select .input {
  position: relative;
  z-index: 1;
}

.filter__body {
  display: table;
}
.filter__body-history {
  overflow: visible;
}
.css-ess3ih {
  z-index: 0 !important;
}
.css-ncbr6b {
  z-index: 0 !important;
}
.css-1d1h06e {
  z-index: 0 !important;
}
.rs__cell.rs__time {
  z-index: 0 !important;
}
.css-1dune0f-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 8px 14px !important;
}
.transactions-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.transactions-table th,
.transactions-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.transactions-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.transactions-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.transactions-table tr:hover {
  background-color: #f1f1f1;
}

.transactions-table td {
  font-size: 14px;
}

.grid-total {
  margin-bottom: 2rem;
}

.page__topbar {
  position: relative;
}

.confirm {
  position: absolute;
  top: 3.125rem;
  right: 0;

  max-width: 14.875rem;
  padding: 1rem 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  background: #ffffff;
  border: 0.76px solid #cacdd8;
  box-shadow: 0px 4px 16px rgba(18, 28, 45, 0.2);
  border-radius: 3.04px;
}

.confirm__title {
  margin-bottom: 1.25rem;
  font-weight: 700;
  text-align: center;
}

.confirm__actions {
  display: flex;
  align-items: center;
  column-gap: 1rem;
}

.confirm__btn {
  height: 2.25rem;
  width: 2.875rem;

  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 500;

  border: 1px solid #8891aa;
  border-radius: 4px;
}

.content__wrap--full {
  margin-bottom: 2rem;
  align-items: unset;
}

.page__right {
  overflow-x: auto;
}

.page__right .css-s6u7kg,
.page__right .css-ess3ih {
  overflow: hidden;
}

.history-item__title {
  cursor: pointer;
}

.css-1mrufi::-webkit-scrollbar {
  height: 1rem;
}

.css-1mrufi::-webkit-scrollbar-track {
  background: var(--bg-muted);
}

.css-1mrufi::-webkit-scrollbar-thumb {
  background-color: var(--border-primary);
}
